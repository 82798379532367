:root {
	--dark-navy: #020c1b;
	--navy: #0d1117;
	--light-navy: #26292f;
	--lightest-navy: #4ada7c1f;
	--navy-shadow: rgba(2, 12, 27, 0.7);
	--slate: #8892b0;
	--light-slate: #a8b2d1;
	--lightest-slate: #ccd6f6;
	--white: #e6f1ff;
	--green-bright: #4ada7c;
	--green-tint: rgba(100, 255, 218, 0.1);
}

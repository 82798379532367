#space-scene {
	width: 160%;
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent; /* Ensure the background is transparent */
}

@media (max-width: 768px) {
	#space-scene {
		height: 300px; /* Adjust the height for smaller screens */
	}
}

@media (max-width: 480px) {
	#space-scene {
		height: 250px; /* Further adjust the height for very small screens */
	}
}

#intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: auto;
	max-width: 1000px;
	padding-top: 0px;
	min-height: 100vh;
}

.Typist {
	color: var(--lightest-slate);
	text-align: center;
	font-size: 70px;
	margin-top: -30px;
	padding-bottom: 10px;
}
.Typist .Cursor--blinking {
	color: var(--green-bright);
}

.intro-title {
	font-family: "JetBrains Mono", "Nunito Sans", sans-serif;
	font-size: 60px;
}
.coming-soon {
	font-family: "JetBrains Mono", "Nunito Sans", sans-serif;
	font-size: 60px;
}
.intro-name {
	font-family: "JetBrains Mono", "Nunito Sans", sans-serif;
	color: var(--green-bright);
	font-weight: bold;
	font-size: 70px;
}

.intro-subtitle {
	margin-top: -36px;
	font-family: "Nunito Sans", sans-serif;
	color: var(--slate);
	text-align: center;
	font-weight: 300;
	font-size: 50px;
}

.intro-desc {
	color: var(--slate);
	font-family: "Nunito Sans", sans-serif;
	font-size: 22px;
	text-align: center;
	max-width: 600px;
	padding-bottom: 35px;
}

.intro-contact {
	font-size: 22px;
	font-weight: bolder;
	font-family: "Nunito Sans", sans-serif;
	align-self: center;

	padding: 17px 30px;
	cursor: pointer;
	border: 1px solid var(--green-bright);
	border-radius: 50px;
}

.intro-contact:hover {
	background-color: var(--lightest-navy);

	font-weight: bolder;
}

b {
	font-size: 17px;
}

@media only screen and (max-width: 600px) {
	#intro {
		min-height: unset;
		padding-left: unset;
		padding-top: unset;
		margin: 0;
		align-items: center;
	}

	.intro-title {
		font-size: 30px;
	}
	.coming-soon {
		font-size: 30px;
	}

	.intro-name {
		font-size: 35px;
	}

	.Typist {
		font-size: 30px;
		padding-bottom: unset;
	}

	.intro-subtitle {
		margin: 0;
		font-size: 30px;
	}

	.intro-desc {
		font-size: 15px;
		max-width: unset;
	}

	.intro-contact {
		font-size: 16px;
	}
}

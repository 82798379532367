.navbar {
	background-color: var(--navy);
	color: var(--lightest-slate);
	font-family: "JetBrains Mono", "Roboto", sans-serif;
	font-weight: bold;
}

.navbar-light .navbar-brand {
	color: var(--lightest-slate) !important;
}

.navbar-light .navbar-nav .nav-link {
	color: var(--lightest-slate) !important;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: var(--green-bright) !important;
}

@media only screen and (max-width: 600px) {
	.navbar-light .navbar-brand {
		display: none;
	}

	.navbar-light .navbar-nav.me-auto .nav-link {
		display: none;
	}
}

body {
	margin: 0;
	font-family: "Nunito Sans", "JetBrains Mono", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

code {
	font-family: "Nunito Sans", "JetBrains Mono", "Roboto", sans-serif;
}

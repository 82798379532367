#credits {
	display: flex;
	align-items: center;
	padding-top: 100px;
}

.ending-credits {
	font-family: "JetBrains Mono", "Roboto", sans-serif;
	color: var(--slate);
	text-align: center;
	width: 100%;
	font-size: 16px;
	padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
	.ending-credits {
		font-size: 12px;
		padding-bottom: 20px;
	}
}
